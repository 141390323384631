import { AppComponent } from 'typescript/typings';
import RoomState from 'components/room/RoomState';
import { RoomStates } from '@internal/state/room';
import styled from 'styled-components';
import { rem } from 'polished';
import SEO from 'components/SEO';
import { useEffect } from 'react';
import { useAppSelector } from 'state/hooks';
import { captureException } from '../utils/sentry';

interface PageError {
    code: 404 | 500;
}

const GIFS = {
    [404]: 'https://giphy.com/embed/b1YtwNsVRgyJ5IbvM3',
    [500]: 'https://giphy.com/embed/WUrgA8xOSUJj6JZoJC',
};

const PageError: AppComponent<PageError> = ({ code }) => {
    const initialized = useAppSelector((state) => state.user.initialized);

    useEffect(() => {
        if (initialized) {
            captureException(new Error(code === 404 ? 'Page not found' : 'Fatal page error'), {
                statusCode: code,
                appContext: 'error',
            });
        }
    }, [initialized, code]);

    return (
        <PageErrorStyled>
            <SEO title={code === 404 ? 'Page not found' : 'Something went wrong'} />
            <RoomState state={code === 404 ? RoomStates.RoomNotFound : RoomStates.Error} logo>
                {code === 404 ? 'Hmm... we can’t find that page 🤔' : undefined}
            </RoomState>
            <GifStyled
                src={GIFS[code]}
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen={false}
            />
        </PageErrorStyled>
    );
};

const GifStyled = styled.iframe`
    height: ${rem(280)};
    width: ${rem(280)};
    border-radius: ${rem(12)};
    pointer-events: none;
`;

const PageErrorStyled = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${rem(20)};
    box-sizing: border-box;
`;

export default PageError;
